// In src/Project.js

import React from 'react';
import NotFound from './NotFound';
import PrismicReact from 'prismic-reactjs';
import { Col, Container, Row } from 'reactstrap';
import AllProjects from '../components/AllProjects';
import Loading from '../components/Loading';

// Declare your component
export default class Project extends React.Component {

    state = {
        doc: null,
        notFound: false,
    }

    componentWillMount() {
        this.fetchProject(this.props);
    }

    componentWillReceiveProps(props) {
        this.fetchProject(props);
    }

    componentDidUpdate() {
        this.props.prismicCtx.toolbar();
    }

    fetchProject(props) {

        if (props.prismicCtx) {
            // We are using the function to get a document by its uid
            return props.prismicCtx.api.getByID(props.match.params.uid, {}, (err, doc) => {
                if (doc) {
                    // We put the retrieved content in the state as a doc variable
                    this.setState({ doc });
                } else {
                    // We changed the state to display error not found if no matched doc
                    this.setState({ notFound: !doc });
                }
            });
        }
        return null;
    }

    renderImage = (image) => {
        return <img className="img-fluid" src={image.image.url} title="" alt={image.image.alt === null ? '' : image.image.alt} />
    };

    render() {
        if (this.state.doc) {

            const doc = this.state.doc;

            return (
                <div>
                    <Container className="content">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }} data-wio-id={doc.id} className="text-center">
                                <h1 className="text-center">{PrismicReact.RichText.asText(doc.data.name)}</h1>
                            </Col>
                        </Row>
                        <Row className="project-images">
                            {Object.keys(doc.data.video).length > 0 ?
                                <Col className="project-image" sm="12" md={{ size: 8, offset: 2 }}>
                                    <div className="videoWrapper" dangerouslySetInnerHTML={{ __html: doc.data.video.html }} />
                                </Col> : ''}
                            {doc.data.images.map((image, index) => (
                                <Col className="project-image" sm="12" md={{ size: 8, offset: 2 }} key={index}>
                                    {this.renderImage(image)}
                                </Col>))}
                        </Row>
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }} data-wio-id={doc.id} className="text-center">
                                <div className="text-center text-muted">
                                    {PrismicReact.RichText.render(doc.data.description, this.props.prismicCtx.linkResolver)}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <footer>
                        <Container>
                            <h3>Other projects</h3>
                            <AllProjects prismicCtx={this.props.prismicCtx} />
                        </Container>
                    </footer>
                </div>)
        } else if (this.state.notFound) {
            return <NotFound />;
        }
        return <Loading />;
    }
}
