import React from 'react';
import ProjectThumb from '../components/ProjectThumb';
import { Col, Row } from 'reactstrap';
import Prismic from 'prismic-javascript';
import Loading from './Loading';

// Declare your component
export default class AllProjects extends React.Component {

    state = { projects: [] }

    componentWillMount() {
        this.fetchAllProjects(this.props);
    }

    componentWillReceiveProps(props) {
        this.fetchAllProjects(props);
    }

    componentDidUpdate() {
        this.props.prismicCtx.toolbar();
    }

    fetchAllProjects(props) {
        if (props.prismicCtx) {
            props.prismicCtx.api.query(
                Prismic.Predicates.at('document.type', 'project'),
                { pageSize: 100, orderings: '[my.project.priority desc]' }
            ).then((response) => {
                if (response.results.length > 0) {
                    this.setState({ projects: response.results })
                }
            });
        }
        return null;
    }

    renderProject = (project) => (
        <ProjectThumb uid={project.id} prismicCtx={this.props.prismicCtx} />
    );

    render() {
        if (this.state.projects) {
            return (
                <Row>
                    {this.state.projects.map((project, index) => (
                        <Col xs="6" sm="4" key={index}>
                            {this.renderProject(project)}
                        </Col>))}
                </Row>
            );
        }
        return <Loading />;
    }
}
