import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import Preview from './Preview';
import NotFound from './containers/NotFound';
import './stylesheets/bootstrap.css';
import Page from './containers/Page';
import Project from './containers/Project';
import ScrollToTop from './components/ScrollToTop';


const App = (props) => (
    <Router>
        <ScrollToTop>
            <Switch>
                <Redirect exact from="/" to="/page/homepage"/>
                <Route exact path="/page/:uid" render={routeProps =>
                    <Page {...routeProps} prismicCtx={props.prismicCtx}/>}/>
                <Route exact path="/project/:uid" render={routeProps =>
                    <Project key={routeProps.location.key} {...routeProps} prismicCtx={props.prismicCtx}/>}/>
                <Route exact path="/preview" render={routeProps =>
                    <Preview {...routeProps} prismicCtx={props.prismicCtx}/>}/>
                <Route component={NotFound}/>
            </Switch>
        </ScrollToTop>
    </Router>
);

export default App;
