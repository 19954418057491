import React from 'react';
import PrismicReact from 'prismic-reactjs';
import { Col, Container, Row } from 'reactstrap';
import Loading from '../components/Loading';
import AllProjects from '../components/AllProjects';


export default class Page extends React.Component {

    state = {
        doc: null,
        notFound: false,
    }

    componentWillMount() {
        this.fetchPage(this.props);
    }

    componentWillReceiveProps(props) {
        this.fetchPage(props);
    }

    componentDidUpdate() {
        this.props.prismicCtx.toolbar();
    }

    fetchPage(props) {
        if (props.prismicCtx) {
            // We are using the function to get a document by its uid
            return props.prismicCtx.api.getByUID('page', props.match.params.uid, {}, (err, doc) => {
                if (doc) {
                    // We put the retrieved content in the state as a doc variable
                    this.setState({ doc });
                } else {
                    // We changed the state to display error not found if no matched doc
                    this.setState({ notFound: !doc });
                }
            });
        }
        return null;
    }

    render() {
        if (this.state.doc) {

            const doc = this.state.doc;

            return (
                <Container fluid={true} className="content">
                    <Row>
                        <Col md={{ size: 4, offset: 4 }} data-wio-id={doc.id}>
                            <h1 className="text-center">{PrismicReact.RichText.asText(doc.data.title)}</h1>

                            {Object.keys(doc.data.image).length > 0 ?
                                <img alt="cover" style={{ width: '150px' }} src={doc.data.image.url} /> : ''}

                            <div className="text-center">
                                {PrismicReact.RichText.render(doc.data.description, this.props.prismicCtx.linkResolver)}
                            </div>
                        </Col>
                    </Row>

                    {this.props.match.params.uid === 'homepage' ?
                        <AllProjects prismicCtx={this.props.prismicCtx} /> : ''}
                </Container>
            );
        } else if (this.state.notFound) {
            return null;
        }
        return <Loading />;
    }
}
