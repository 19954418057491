import React from 'react';
import ReactDOM from 'react-dom';
import PrismicApp from './PrismicApp';
import './index.css';
import Navigation from './components/Navigation';

ReactDOM.render(
    <div>
        <Navigation />
        <PrismicApp />
    </div>,
    document.getElementById('root'),
);
