// In src/Project.js

import React from 'react';
import NotFound from '../containers/NotFound';
import PrismicReact from 'prismic-reactjs';
import { Card, CardTitle, CardBody, CardImg } from 'reactstrap';
import { Link } from 'react-router-dom';
import Loading from './Loading';

// Declare your component
export default class ProjectThumb extends React.Component {

    state = {
        doc: null,
        notFound: false,
    }

    componentWillMount() {
        this.fetchProject(this.props);
    }

    componentWillReceiveProps(props) {
        this.fetchProject(props);
    }

    componentDidUpdate() {
        this.props.prismicCtx.toolbar();
    }

    fetchProject(props) {
        if (props.prismicCtx) {
            // We are using the function to get a document by its uid
            return props.prismicCtx.api.getByID(props.uid, {}, (err, doc) => {
                if (doc) {
                    // We put the retrieved content in the state as a doc variable
                    this.setState({ doc });
                } else {
                    // We changed the state to display error not found if no matched doc
                    this.setState({ notFound: !doc });
                }
            });
        }
        return null;
    }

    render() {
        if (this.state.doc) {

            const doc = this.state.doc;

            return (
                <Card>
                    <Link to={`/project/${doc.id}`}><CardImg className="img-fluid" width="100%" src={doc.data.image.url} alt={PrismicReact.RichText.asText(doc.data.name)} /></Link>
                    <CardBody>
                        <CardTitle><h5>{PrismicReact.RichText.asText(doc.data.name)}</h5></CardTitle>
                    </CardBody>
                </Card>
            )
        } else if (this.state.notFound) {
            return <NotFound />;
        }
        return <Loading />;
    }
}
